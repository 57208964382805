.projects-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.coming-soon-card {
  position: relative;
  padding: 3rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 2rem auto;
}

.pulse-animation {
  position: absolute;
  width: 100px;
  height: 100px;
  background: #6366f1;
  border-radius: 50%;
  opacity: 0.2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.2;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0.1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.2;
  }
}

.tech-stack {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.tech-stack span {
  padding: 0.5rem 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2rem;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.tech-stack span:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: translateY(-2px);
}

/* Responsive design */
@media (max-width: 768px) {
  .projects-container {
    padding: 1rem;
  }
  
  .coming-soon-card {
    padding: 2rem;
    margin: 1rem;
  }
  
  .tech-stack {
    gap: 0.5rem;
  }
  
  .tech-stack span {
    font-size: 0.8rem;
    padding: 0.4rem 0.8rem;
  }
} 