.floating-navbar {
  position: fixed;
  bottom: 2rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
  max-width: 90%;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(8px);
  border-radius: 2px;
  z-index: 1000;
  padding: 0.5rem 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}

.floating-nav-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.floating-navbar .nav-link {
  font-family: var(--font-heading);
  font-size: 0.8rem;
  letter-spacing: 0.15em;
  color: #333333;
  text-decoration: none;
  font-weight: 200;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
}

.floating-navbar .nav-link:hover {
  color: #666666;
}

.floating-navbar .nav-icon {
  height: 20px;
  width: auto;
  transition: transform 0.2s ease;
  margin: 0 0.25rem;
}

.floating-navbar .home-link:hover .nav-icon {
  transform: scale(1.1);
}

.floating-navbar .nav-separator {
  color: #666666;
  font-weight: 200;
  opacity: 0.5;
  margin: 0 0.25rem;
}

@media screen and (max-width: 768px) {
  .floating-navbar {
    bottom: 1.5rem;
    padding: 0.4rem 1rem;
    max-width: 95%;
  }

  .floating-nav-list {
    gap: 0.5rem;
  }

  .floating-navbar .nav-link {
    font-size: 0.7rem;
  }

  .floating-navbar .nav-icon {
    height: 16px;
    margin: 0 0.2rem;
  }

  .floating-navbar .nav-separator {
    margin: 0 0.2rem;
  }
}

@media screen and (max-width: 480px) {
  .floating-navbar {
    bottom: 1rem;
    padding: 0.3rem 0.8rem;
  }

  .floating-nav-list {
    gap: 0.3rem;
  }

  .floating-navbar .nav-link {
    font-size: 0.65rem;
  }

  .floating-navbar .nav-icon {
    height: 14px;
  }
} 